import React from "react"

const Picture = ({ title, image }) => {
  return (
    <div className="text-center flex flex-col gap-4">
      <span className="text-3xl md:text-5xl font-oswald">{title}</span>
      <img src={image} className="w-60 object-contain" alt={title} />
    </div>
  )
}

export default Picture
