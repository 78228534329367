import React from "react"
import Layout from "../components/common/layout"
import HeadGenerator from "../components/common/seo"
import Main from "../components/nosotros/main"
import data from "../data/fields"
import summaryImage from "../images/summary_image.webp"

const Nosotros = () => {
  return (
    <Layout hasBackground>
      <Main />
    </Layout>
  )
}

export default Nosotros

export const Head = () => (
  <HeadGenerator
    data={{
      title: "Nosotros - Jockey On Ice",
      description:
        "¿Quiénes somos y cuál es nuestro propósito? Conoce más sobre nuestra organización.",
      url: data.url.nosotros,
      image: {
        srcWebp: summaryImage,
        widh: 1200,
        height: 630,
        altText: data.strings.seo.default.title,
      },
    }}
    largeSummaryImage
  />
)
