import React from "react"
import { SimplePaddedCard } from "../../common/cards"

const TextCloud = ({ title, text }) => {
  return (
    <SimplePaddedCard>
      <div className="flex flex-col gap-2">
        <h2 className="w-full text-left text-xl font-bold">{title}</h2>
        <span className="w-full text-justify">{text}</span>
      </div>
    </SimplePaddedCard>
  )
}

export default TextCloud
