import React from "react"
import TextCloud from "../textCloud"
import Token from "../../common/token/index"
import maleSkater from "../../../images/icons/male-skater.svg"
import flag from "../../../images/icons/flag.svg"
import Picture from "../../../components/nosotros/picture"
import pasion from "../../../images/pasion.png"
import familia from "../../../images/familia.png"
import disciplina from "../../../images/disciplina.png"
import { LayoutSection } from "../../common/layout"
import data from "../../../data/fields"

const Main = () => {
  return (
    <LayoutSection>
      <div className="w-full flex justify-center bg-gradient-radial from-gray-50 via-gray-50 to-transparent">
        <div className="mx-auto w-full max-w-5xl flex-col border-b-0 flex px-8 py-10 gap-8">
          <p className="text-5xl md:text-7xl w-full text-center font-oswald">
            Somos amantes del patinaje
          </p>
          <div className="flex flex-col-reverse items-center md:flex-row w-full gap-4">
            <div className="text-start flex items-center shrink">
              <TextCloud title="Nuestra misión" text={data.strings.mission} />
            </div>
            <div className="w-max">
              <Token front={<img src={flag} alt="Bandera de Perú" />} />
            </div>
          </div>
          <div className="flex flex-col items-center md:flex-row w-full gap-4">
            <div className="w-max">
              <Token front={<img src={maleSkater} alt="Patinador" />} />
            </div>
            <div className="text-start flex items-center shrink">
              <TextCloud title="Nuestra visión" text={data.strings.vision} />
            </div>
          </div>
          <div></div>
          <div className="flex flex-col md:flex-row justify-between items-center gap-8">
            <Picture title="Pasión" image={pasion} />
            <Picture title="Familia" image={familia} />
            <Picture title="Disciplina" image={disciplina} />
          </div>
        </div>
      </div>
    </LayoutSection>
  )
}

export default Main
